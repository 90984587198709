export default defineNuxtPlugin(() => {
  useHead({
    script: [
      {
        src: '//x.cnt.my/async/track/?r=' + Math.random(),
        async: true,
        tagPosition: 'head',
      },
    ],
  });
});
