export const movieFavoriteParamUpdKey = 'movie-favorite-update';
export const movieUserRatingUpdKey = 'movie-rating-update';
export const noveltyMoviesBaseKeys = [movieFavoriteParamUpdKey, movieUserRatingUpdKey];

export const movieCacheKeys = {
  worldPremieres: ['world-premieres', movieFavoriteParamUpdKey, movieUserRatingUpdKey],
  popularFilms: ['popular-films', movieFavoriteParamUpdKey, movieUserRatingUpdKey],
  filmCollections: ['film-collections', movieFavoriteParamUpdKey, movieUserRatingUpdKey],
  userFavoriteMovies: ['user-favorite-movies'],
  watchedMovies: ['viewed_page_key'],
  watchingMovies: ['viewed_movies'],
};
