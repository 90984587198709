import { markRaw } from 'vue';
import { object, string, lazy } from 'yup';

const message: { [key: string]: string } = {
  required: 'Обязательное поле',
  login: 'Введите корректный номер телефона или e-mail (Например: +79876543210 или example@example.com)',
  loginKg: 'Неверный email',
  email: 'Введите корректный номер телефона или e-mail (Например: +79876543210 или example@example.com)',
  emailKg: 'Введите корректный e-mail (Например: example@example.com)',
  phone: 'Введите корректный номер телефона или e-mail (Например: +79876543210 или example@example.com)',
  phoneKg: 'Разрешен только email',
  code: 'Код должен содержать 4 символа',
  number: 'Код содержит только числа',
  length: 'Максимальная длина e-mail адреса: 254 символа',
  onlyPhone: 'Введите корректный номер телефона',
  onlyEmail: 'Введите корректный e-mail',
};
export const loginValidation = () => {
  const isTargetKgDomain = useState('isTargetKgDomain');
  return {
    start: markRaw(
      object({
        login: lazy(value => {
          const regex = /^[\d+\-\(\)\s]+$/;
          const isDigitsOnly = regex.test(value);
          if (isTargetKgDomain.value) {
            return string().email(message.emailKg).required(message.loginKg);
          } else {
            if (isDigitsOnly) return string().phone(message.phone).required(message.login);
            else return string().email(message.email).required(message.login).max(254, message.length);
          }
        }),
      }),
    ),
    check: markRaw(
      object({
        code: string().required(message.required).matches(/^\d+$/, message.number),
      }),
    ),
    phone: object({
      login: string().phone(message.onlyPhone).required(message.onlyPhone),
    }),
    email: object({
      login: string().email(message.onlyEmail).required(message.onlyEmail).max(254, message.length),
    }),
  };
};
