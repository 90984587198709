<script lang="ts" setup>
  import type { IBanner } from '~/types/movies';
  import { useBanner } from '~/composables/useBanner';

  import VButton from '~/components/ui/VButton.vue';

  const MovieRating = defineAsyncComponent(() => import('~/components/movies/MovieRating.vue'));
  const MovieInfoGroup = defineAsyncComponent(() => import('~/components/movies/MovieInfoGroup.vue'));

  interface IProps {
    slide: IBanner;
    active?: boolean;
    index?: number;
  }
  const props = withDefaults(defineProps<IProps>(), {});

  const {
    images,
    rating,
    name,
    text,
    genre,
    minAge,
    route,
    content,
    film,
    isFilm,
    graphicTitle,
  } = useBanner(props.slide);
  const { subscriptionActive } = storeToRefs(useAuthStore());
</script>

<template>
  <div class="movie-slide" :class="{ active }" @click="navigateTo(route)">
    <div class="movie-slide__image">
      <picture>
        <source v-if="images.mobile" :srcset="images.mobile" media="(max-width: 640px)" />
        <img
          :src="String(images.middle)"
          :alt="name"
          :loading="index ? 'lazy' : 'eager'"
          :fetchPriority="index ? 'auto' : 'high'"
        />
      </picture>
      <!--      <v-image :src="`https://test-back-films.ru${image}`" fetchPriority="high" :alt="name" transition="fade">-->
      <!--        <template #loading> </template>-->
      <!--        <template #error> </template>-->
      <!--      </v-image>-->
    </div>
    <div class="movie-slide__overlay container">
      <div
        v-if="content"
        class="movie-slide__content"
        data-swiper-parallax-x="30%"
        data-swiper-parallax-opacity="0"
        :class="{ active }"
      >
        <img
          v-if="graphicTitle"
          :src="String(graphicTitle)"
          :alt="name"
          class="movie-slide__graphic-title"
        />
        <div v-else class="movie-slide__title">{{ name }}</div>
        <div class="movie-slide__genres">
          {{ genre }} <span v-if="minAge">{{ minAge }}+</span>
        </div>
        <p class="movie-slide__description" v-html="text"></p>
        <div class="movie-slide__filters">
          <div class="movie-slide__filters-item">
            <movie-rating v-for="(item, key) in rating" :key="key" :rating="item" :name="key" />
          </div>
          <movie-info-group class="movie-slide__info-group">
            <span v-if="film.year">{{ film.year }}</span>
            <span v-if="film.countries">{{ film.countries }}</span>
            <span v-if="film.duration && isFilm">{{ film.duration }}</span>
          </movie-info-group>
        </div>
        <v-button class="movie-slide__button" media="normal" :to="route">
          Перейти к {{ isFilm ? 'фильму' : 'сериалу' }}
        </v-button>
        <v-button
          v-if="!subscriptionActive"
          class="movie-slide__button-promo"
          appearance="outline"
          color="white"
          size="normal"
          to="/subscriptions#subscription-promocode"
        >
          Промокод
        </v-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @mixin animation($opacity, $transform) {
    opacity: $opacity;
    transform: translateY($transform);
  }
  .movie-slide {
    $parent: &;
    display: grid;
    align-items: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &__image {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 0;
      img {
        position: absolute;
        inset: 0;
        object-fit: cover;
        width: 100%;
      }
    }
    &__overlay {
      position: absolute;
      inset: 0;
      background:
        linear-gradient(180deg, rgba(11, 11, 11, 0) 10.13%, #0b0b0b 85.45%),
        rgba(211, 211, 211, 0) 0.117px 0.563px / 115.723% 121.735% no-repeat;
      display: grid;
      align-content: flex-end;
      padding-bottom: 40px;
      z-index: 1;
      cursor: pointer;
    }
    &__content {
      position: relative;
      display: grid;
      gap: 16px;
      max-width: 540px;
      transition: 1s ease-out;
      //transition-delay: 0.2s;
      @include animation(0.5, 1rem);
      &.active {
        @include animation(1, 0);
      }
    }
    &__button {
      width: 240px;
    }
    &__title {
      color: $main_white;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
    }
    &__genres {
      display: flex;
      gap: 16px;
      color: $main_white;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      span {
        font-weight: 600;
      }
    }
    &__description {
      color: $main_white;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__filters {
      display: flex;
      gap: 16px;
      align-items: center;
      span {
        color: $main_white;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
    &__info-group {
      gap: 16px;
    }
    &__filters-item {
      display: flex;
      gap: 16px;
    }
    &__divider {
      width: 1px;
      height: 16px;
      background-color: $main_white;
      opacity: 0.5;
    }
    &__button-promo {
      display: none;
    }
    @media (max-width: $retina) {
      position: relative;
      grid-template-rows: max-content 1fr;
      height: auto;
      &__image {
        position: relative;
        height: 238px;
        overflow: hidden;
        &:after {
          content: '';
          position: absolute;
          inset: 0;
          background:
            linear-gradient(180deg, rgba(11, 11, 11, 0) 0%, #0b0b0b 100%),
            #d3d3d300 50% / cover no-repeat;
        }
      }
      &__content {
        //transition: none;
        transform: none;
      }
      &__overlay {
        position: relative;
        padding: 0 0 16px;
      }
      &__graphic-title {
        object-fit: contain;
      }
      &__title {
        font-size: 24px;
        line-height: 32px;
      }
      &__description {
        $descLineHeight: 24px;
        $descRowsCount: 3;
        font-size: 16px;
        line-height: $descLineHeight;
        -webkit-line-clamp: $descRowsCount;
        min-height: calc($descLineHeight * $descRowsCount);
      }
      &__filters-item {
        gap: 8px;
      }
      &__filters {
        flex-direction: column;
        align-items: flex-start;
      }
      &__info-group {
        gap: 8px !important;
      }
      &__button {
        width: 100%;
      }
      &__button-promo {
        display: flex;
      }

      &__genres {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
</style>
