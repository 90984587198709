<script lang="ts" setup>
  interface IProps {
    isProfile?: boolean;
  }
  const props = defineProps<IProps>();

  const items = [
    {
      name: 'Подписка',
      to: {
        name: 'profile',
      },
    },
    {
      name: 'Мои данные',
      to: {
        name: 'profile-user',
      },
    },
    // {
    //   name: 'Способы оплаты',
    //   to: {
    //     name: 'profile-payments',
    //   },
    // },
    {
      name: 'Уведомления',
      to: {
        name: 'profile-notifications',
      },
    },
    {
      name: 'Устройства и сеансы',
      to: {
        name: 'profile-devices',
      },
    },
    {
      name: 'Настройки',
      to: {
        name: 'profile-settings',
      },
    },
    {
      name: 'Бонусы',
      to: {
        name: 'profile-bonuses',
      },
    },
  ];
</script>

<template>
  <ul class="profile-links" :class="{ 'profile-links--sidebar': props.isProfile }">
    <li v-for="link in items">
      <nuxt-link class="profile-links__item" :to="link.to">
        <span>{{ link.name }}</span>
        <icon-caret v-if="!props.isProfile" />
      </nuxt-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
  .profile-links {
    &__item {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      color: $main_white;
      transition: 0.3s;
      padding: 8px 0;
      svg {
        transform: rotate(-90deg);
      }
      &:hover {
        color: $main_grey_dark;
      }
    }
    &--sidebar {
      .router-link-active::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: -24px;
        width: 4px;
        height: 100%;
        background-color: $main_yellow_light;
      }
    }
  }
</style>
