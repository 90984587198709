import { useMediaQuery } from '@vueuse/core';

export default function useScreen(checkHydration = true) {
  const screenSizes = {
    laptopSmall: '1366px',
    tabletLarge: '1280px',
    tablet: '1024px',
    tabletSmall: '768px',
    retina: '640px',
    mobileLarge: '480px',
    mobile: '380px',
  };
  const isHydrated = ref(!checkHydration);

  if (checkHydration) {
    onMounted(() => isHydrated.value = true);
  }

  const isLaptopSmall = useMediaQuery(`(max-width: ${screenSizes.laptopSmall})`);
  const isTabletLarge = useMediaQuery(`(max-width: ${screenSizes.tabletLarge})`);
  const isTablet = useMediaQuery(`(max-width: ${screenSizes.tablet})`);
  const isTabletSmall = useMediaQuery(`(max-width: ${screenSizes.tabletSmall})`);
  const isRetina = useMediaQuery(`(max-width: ${screenSizes.retina})`);
  const isMobileLarge = useMediaQuery(`(max-width: ${screenSizes.mobileLarge})`);
  const isMobile = useMediaQuery(`(max-width: ${screenSizes.mobile})`);

  return {
    screenSizes,
    isLaptopSmall: computed(() => isHydrated.value && isLaptopSmall.value),
    isTabletLarge: computed(() => isHydrated.value && isTabletLarge.value),
    isTablet: computed(() => isHydrated.value && isTablet.value),
    isTabletSmall: computed(() => isHydrated.value && isTabletSmall.value),
    isRetina: computed(() => isHydrated.value && isRetina.value),
    isMobileLarge: computed(() => isHydrated.value && isMobileLarge.value),
    isMobile: computed(() => isHydrated.value && isMobile.value),
  };
};