export const useHeader = () => {
  const { afterEach } = useRouter();
  const mobileHeader = useState('mobile-header');
  const { isRetina } = useScreen(false);

  const setHeader = (value: string) => {
    if (isRetina.value) {
      mobileHeader.value = value;
    }
  };
  const resetHeader = () => {
    mobileHeader.value = '';
  };
  afterEach(() => resetHeader());
  return {
    mobileHeader,
    setHeader,
    resetHeader,
  };
};
