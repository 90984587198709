<script setup lang="ts">
  import { useField } from 'vee-validate';
  import { ref, computed } from 'vue';

  interface IProps {
    type?: string;
    inputType?: string;
    value?: string;
    name: string;
    label?: string;
    placeholder?: string;
    mask?: string;
    modelValue?: string;
    errorMessage?: string;
    required?: boolean;
    showError?: boolean;
    disabled?: boolean;
    transparent?: boolean;
    autofocus?: boolean;
    canClear?: boolean;
    validate?: 'change' | 'input';
  }
  const props = withDefaults(defineProps<IProps>(), {
    type: 'text',
    inputType: 'input',
    required: false,
    showError: true,
    transparent: false,
    validate: 'input',
  });
  const emits = defineEmits<{ onInput: [] }>();

  const {
    value: inputValue,
    errorMessage: error,
    handleBlur,
    handleChange,
    setErrors,
    meta,
  } = useField(() => props.name, undefined, {
    syncVModel: true,
  });

  watch(
    () => props.errorMessage,
    value => value && setErrors(value),
  );

  const isVisiblePass = ref(false);
  const refInput = ref(null);
  const bullet = computed(() => props.type === 'password' && inputValue.value && !isVisiblePass.value);

  const handleClear = () => {
    inputValue.value = '';
  };

  onMounted(() => {
    if (props.autofocus && refInput.value) {
      nextTick(() => refInput.value.focus());
    }
  });
</script>

<template>
  <div class="v-input v-input__group" :class="{ error, success: meta.valid, bullet }">
    <div class="v-input__wrapper">
      <label :for="name" :class="{ required }">{{ label }}</label>
      <div v-if="type === 'search'" class="v-input__icon v-input__icon--search">
        <component :is="'icon-search'" class="search-icon" />
      </div>
      <component
        :is="inputType!"
        :id="name"
        ref="refInput"
        :class="['v-input__field', { transparent }]"
        :type="isVisiblePass ? 'text' : props.type"
        :name="name"
        :value="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @[props.validate]="handleChange"
      />
      <div v-if="props.canClear && inputValue" class="v-input__icon v-input__icon--toggle" @click="handleClear">
        <component :is="'icon-close'" />
      </div>
      <div v-if="$slots.controls" class="v-input__controls">
        <slot name="controls" />
      </div>
      <div
        v-if="type === 'password'"
        class="v-input__icon v-input__icon--toggle"
        @click="isVisiblePass = !isVisiblePass"
      >
        <!-- <v-icon :icon="isVisiblePass ? 'eye-open' : 'eye'" /> -->
        <component :is="'icon-eye-open'" v-if="isVisiblePass" />
        <component :is="'icon-eye-close'" v-else />
      </div>
    </div>
    <transition name="pop-px">
      <div v-if="props.showError && error && error !== 'empty'" class="v-input__message">
        {{ error }}
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
  .v-input {
    &__group {
      position: relative;
    }
    &__wrapper {
      position: relative;
      display: flex;
      align-items: center;
      textarea {
        height: 100px;
      }
    }
    &__field {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      padding: 12px 8px 12px 16px;
      border-radius: 12px;
      border: 1px solid $secondary_mid;
      background: transparent;
      width: 100%;
      transition: border 0.3s ease-in-out;
      color: $main_white;
      letter-spacing: 1;
      &:focus {
        outline: none;
        border-color: $main_yellow_light;
      }
      &::placeholder {
        color: $secondary_mid;
      }
      &:not(:placeholder-shown) {
        border-color: $main_yellow_light;
      }
      &:disabled {
        background-color: $input-primary-disabled;
        border-color: $input-disabled-stroke;
      }

      &.transparent {
        border-top: transparent;
        border-right: transparent;
        border-left: transparent;
        border-bottom: 1px solid $main_yellow_light;
        border-radius: 0;
        padding-left: 50px;
        &:focus {
          outline: none;
        }

        @media all and (max-width: $mobile) {
          border: 1px solid $main_yellow_light;
          border-radius: 10px;
          padding-left: 15px;
        }
      }

      &[type='search']::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }

      &[type='search']:-webkit-autofill,
      &[type='search']:-webkit-autofill:hover,
      &[type='search']:-webkit-autofill:focus {
        -webkit-text-fill-color: white;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
    &__icon {
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        color: white;
      }

      &--toggle {
        right: 16px;
      }

      &--search {
        left: 16px;
        @media all and (max-width: $mobile) {
          display: none;
        }
      }
    }

    &__message {
      margin-top: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      padding: 0 16px;
      color: $main_red_light;
    }
    &__controls {
      display: flex;
      position: absolute;
      right: 8px;
      backdrop-filter: blur(25px);
      height: 80%;
    }
    .error & {
      &__field {
        border-color: $main_red_light;
      }
    }
    .confirm & {
      &__field {
        border-color: $main_green_light;
      }
    }
    .bullet & {
      &__field {
        letter-spacing: 1px;
      }
    }
    input:disabled {
      background: black;
      border-color: $secondary_grey_mid;
    }
  }
</style>
