import { useNuxtApp } from '#app';

import type {
  ITariff,
  IProfilePayment,
  IMyTariff,
  IProfileNotificationsFilter,
  INotification,
  IUserBonus,
  IUserAccruals,
  IUserCard,
  IProfileUserDevice,
  IRejectReason,
  IAddBalance,
} from '~/types/profile';

import { ProfileApi, type IQueryParams } from '~/services/types';
import type { IPaymentInfo, IResponse } from '~/types/common';
import { useIQuery } from '../useQuery';

export const getTariffs = () => useNuxtApp().$apiFetch<IResponse<ITariff>>(ProfileApi.tariffs, { method: 'get' });

export const getMyPayments = () => useNuxtApp().$apiFetch<IResponse<IProfilePayment>>(ProfileApi.payments);

export const getMySubscription = () => useNuxtApp().$apiFetch<IMyTariff>(ProfileApi.subscription);

export const getMyNotifications = () => useIQuery<INotification[]>(ProfileApi.notificatinons, { pagination: true });

export const getMyNotificationsFilters = () =>
  useNuxtApp().$apiFetch<IResponse<IProfileNotificationsFilter>>(ProfileApi.notificatinonsFilters);

export const updateUserNotificationFilters = (reason: string, active: boolean) => {
  useNuxtApp().$apiFetch(ProfileApi.notificationUpdate, { method: 'put', body: { reason, active } });
};

export const readAllNotifications = () =>
  useNuxtApp().$apiFetch(ProfileApi.readAllUserNotifications, { method: 'put' });

export const getHistoryBonuses = (options: IQueryParams) =>
  useIQuery<IUserBonus[]>(ProfileApi.userBonuses, { ...options, pagination: true });

export const getUserAccruals = (options: IQueryParams) =>
  useIQuery<IUserAccruals[]>(ProfileApi.userAccruals, { ...options, pagination: true });

export const updateUserProfile = (payload: any) =>
  useNuxtApp().$apiFetch(ProfileApi.user, { method: 'PATCH', body: payload });

export const deleteUserInfo = (payload: any) =>
  useNuxtApp().$apiFetch('v5/web/user-info/', { method: 'delete', body: payload });

export const getPaymentGateway = () =>
  useNuxtApp().$apiFetch<{ url: string }>(ProfileApi.addNewUserCard, {
    method: 'post',
    body: { domain: window.location.origin },
  });

export const getUserCards = () => useNuxtApp().$apiFetch<IResponse<IUserCard>>(ProfileApi.userCards);

export const getUserRecurrentCards = () => useNuxtApp().$apiFetch<IResponse<IUserCard>>(ProfileApi.userRecurrentCards);

export const deleteUserCard = (id: number) =>
  useNuxtApp().$apiFetch(`${ProfileApi.userCards}${id}/`, { method: 'DELETE' });

export const deleteUserRecurrentCard = (id: number) =>
  useNuxtApp().$apiFetch(`${ProfileApi.userRecurrentCards}${id}/`, { method: 'DELETE' });

export const getUserDevices = () => useNuxtApp().$apiFetch<IResponse<IProfileUserDevice>>(ProfileApi.devices);

export const deleteAllUserDevices = () => useNuxtApp().$apiFetch(ProfileApi.deleteAllDevice, { method: 'DELETE' });

export const deleteUserDevice = (id: number) =>
  useNuxtApp().$apiFetch(`${ProfileApi.deleteDevice}${id}/`, { method: 'DELETE' });

export const addNewUserDevice = (pin: string) =>
  useNuxtApp().$apiFetch<IProfileUserDevice>(ProfileApi.devices, { method: 'POST', body: { pin } });

export const getRejectOptions = () => useNuxtApp().$apiFetch<IResponse<IRejectReason>>(ProfileApi.getRejectReasons);

export const getPaymentInfo = () => useNuxtApp().$apiFetch<IPaymentInfo>(ProfileApi.paymentInfo);

export const getUserPaymentCard = () => useNuxtApp().$apiFetch<IResponse<IUserCard>>(ProfileApi.userCards);

export const addMoneyToBalance = (payload: IAddBalance) =>
  useNuxtApp().$apiFetch<{ url: string }>(ProfileApi.balanceAdd, { method: 'post', body: payload });

export const getAvailableCountBonuses = () =>
  useNuxtApp().$apiFetch<{ free_films: number }>(ProfileApi.availableBonuses, { method: 'get' });

export const applyDiscount = () =>
  useNuxtApp().$apiFetch(ProfileApi.usedDiscount, { method: 'post', body: { is_used_discount: true } });

export const getReferralOffers = () =>
  useNuxtApp().$apiFetch<IResponse<IMyTariff>>(ProfileApi.offers, { method: 'get' });

export const sendCode = (payload: { email?: string; phone?: string }) =>
  useNuxtApp().$apiFetch<{ time_to_call: number }>(ProfileApi.sendCode, { method: 'post', body: payload });

export const changeLogin = (payload: { email?: string; phone?: string }) =>
  useNuxtApp().$apiFetch(ProfileApi.sendCode, { method: 'post', body: payload });
