import validate from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/middleware/01.setup.global.ts";
import auth_45global from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/middleware/auth.global.ts";
import redirect_45trailing_45slash_45global from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/middleware/redirect-trailing-slash.global.ts";
import manifest_45route_45rule from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  auth_45global,
  redirect_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "no-redirect-to-result": () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/middleware/no-redirect-to-result.ts")
}