<script lang="ts" setup>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination, Autoplay, Parallax } from 'swiper/modules';
  import type { SwiperOptions, Swiper as SwiperType } from 'swiper/types';
  import type { IBanner } from '~/types/movies';
  import type { ExtractPublicPropTypes } from 'vue';
  import { isGeckoEngine } from '~/helpers/browserHelper';
  const HeaderSliderItem = defineAsyncComponent(() => import('~/components/sliders/header/HeaderSliderItem.vue'));

  interface IProps {
    items: IBanner[];
  }
  const props = defineProps<IProps>();
  const modules = [Pagination, Autoplay, Parallax];
  const swiperRef = ref<SwiperType | null>(null);
  const options: SwiperOptions = {
    modules,
    loop: true,
    pagination: {
      enabled: true,
      clickable: true,
    },
    speed: 700,
    parallax: !isGeckoEngine,
    autoplay: {
      delay: 6000,
    },
  };

  const settings: ExtractPublicPropTypes<typeof Swiper> = { ...options };
  const onSwiper = (swiper: SwiperType) => (swiperRef.value = swiper);
</script>

<template>
  <swiper class="header-slider" v-bind="settings" @swiper="onSwiper">
    <swiper-slide v-for="(slide, index) in props.items" :key="slide.id" v-slot="{ isActive }">
      <header-slider-item
        :slide="slide"
        :active="isActive"
        :index="index"
        data-swiper-parallax-x="50%"
        data-swiper-parallax-opacity="0"
      />
    </swiper-slide>
  </swiper>
</template>

<style lang="scss">
  .header-slider {
    height: 100%;
    position: relative;
    //display: grid;
    --swiper-pagination-bottom: 40px;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-bullet-horizontal-gap: 8px;
    --swiper-pagination-bullet-height: 4px;
    transition:
      opacity 0.6s ease,
      transform 0.3s ease;
    .swiper-slide {
      transition: opacity 0.3s ease;
    }
    .swiper-slide-active {
      z-index: 1;
    }
    .swiper-pagination {
      left: 50%;
      width: auto;
      transform: translateX(-50%);
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    .swiper-pagination-bullet {
      width: 14px;
      border-radius: 10px;
      transition: 0.3s;
      &.swiper-pagination-bullet-active {
        width: 48px;
        border-radius: 10px;
        --swiper-pagination-color: #{$main_white};
      }
    }
    @media (max-width: $tablet) {
      .swiper-pagination {
        display: none;
      }
    }
    @media (max-width: $retina) {
      .swiper-pagination {
        display: block;
        --swiper-pagination-bottom: auto;
        --swiper-pagination-top: calc(238px - 34px);
        --swiper-pagination-bullet-horizontal-gap: 4px;
        --swiper-pagination-bullet-height: 2px;
        .swiper-pagination-bullet {
          width: 6px;
          &.swiper-pagination-bullet-active {
            width: 16px;
          }
        }
      }
    }
  }
</style>
