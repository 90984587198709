<script setup lang="ts">
  import VButton from '~/components/ui/VButton.vue';

  import useStatefulCookie from '~/composables/useStatefulCookies';
  import { getSiteElements } from '~/services/modules/siteElements.service';

  const { state } = storeToRefs(useAuthStore());
  const { screenSizes } = useScreen();
  const { hostname } = useRequestURL();
  const hiddenBannerLink = useStatefulCookie<string | null>('hidden_main_page_banner_link');

  const { data, isFetching, suspense } = getSiteElements({
    key: 'main_page_banner',
  });

  const bannerData = computed(() => data.value?.results?.[0]);
  const isBannerVisible = computed(() => (
    Boolean(bannerData.value?.link) && hiddenBannerLink.value !== bannerData.value?.link && !isFetching.value
  ));
  const formattedLink = computed(() => (
    bannerData.value?.link
      ?.replace('{token}', state.value.token)
      .replace('{refresh_token}', state.value.refresh)
      .replace('{domain}', hostname)
  ));

  const hideBanner = () => {
    hiddenBannerLink.value = bannerData.value?.link;
  };

  onServerPrefetch(async () => {
    try {
      await suspense();
    } catch (err) {
      console.error(err);
    }
  });
</script>

<template>
  <section v-if="isBannerVisible" class="section-banner full-width">
    <a :href="formattedLink" class="section-banner__inner">
      <div v-if="bannerData.button_text" class="section-banner__button">
        {{ bannerData.button_text }}
      </div>
      <picture>
        <source
          v-if="bannerData?.image_mobile"
          :srcset="bannerData?.image_mobile"
          :media="`(max-width: ${screenSizes.retina})`"
        >
        <source
          v-if="bannerData?.image_tablet"
          :srcset="bannerData?.image_tablet"
          :media="`(max-width: ${screenSizes.tablet})`"
        >
        <img :src="bannerData?.image" alt="">
      </picture>
      <v-button
        class="section-banner__close"
        icon="close"
        appearance="text"
        @click.stop.prevent="hideBanner"
      />
    </a>
  </section>
</template>

<style scoped lang="scss">
  .section-banner {
    margin-top: $header-height;

    &__button {
      position: absolute;
      left: 42px;
      top: 50%;
      transform: translateY(-50%);

      padding: 6px 15px;

      color: $main_yellow_light;
      font-family: 'Montserrat', sans-serif;
      font-weight: 900;
      font-size: 20px;
      line-height: 30px;

      background-color: $color-main;
      border: 3px solid $main_white;
      border-radius: 35px;
    }

    &__inner {
      position: relative;
      grid-column: 2/5 !important;

      img {
        width: 100%;
      }
    }

    &__close {
      position: absolute;
      top: 4px;
      right: 10px;

      padding: 0;
      width: auto;

      &:deep(svg) {
        color: $main_grey_light !important;
      }
    }

    @media (max-width: $tablet) {
      &__button {
        left: 16px;

        padding: 4px 8px;

        font-size: 14px;
        line-height: 20px;

        border-width: 2px;
      }
    }

    @media (max-width: $retina) {
      margin-top: 0;

      &__button {
        left: 12px;
        padding: 4px 6px;

        font-size: 12px;
        line-height: 16px;
      }

      &__close {
        top: 0;
        right: 0;
      }
    }
  }
</style>
