import { ref } from 'vue';

export const useHover = (interval = 0, removeHoverPopup = false) => {
  const isVisible = ref<boolean>(false);
  const coordinates = ref({
    left: 0,
    top: 0,
  });
  const isHoverDisabled = useRoute().path.startsWith('/favorites');

  if (isHoverDisabled || removeHoverPopup) {
    return {
      coordinates,
      isVisible,
      hover: () => {},
      blur: () => {},
    };
  }

  const canClose = ref<boolean>(false);
  let hoverInterval: ReturnType<typeof setTimeout> | undefined;

  const hover = (event?: any) => {
    const rect = event.target.getBoundingClientRect();
    const screenWidth = window.innerWidth;

    coordinates.value.left = Math.min(
      Math.max(0, rect.left - (560 - rect.width) / 2), // Ограничение слева
      screenWidth - 560, // Ограничение справа
    );
    coordinates.value.top = rect.top + window.scrollY - 50;

    if (hoverInterval) {
      clearTimeout(hoverInterval);
    }

    hoverInterval = setTimeout(() => {
      isVisible.value = true;
      setTimeout(() => {
        canClose.value = true;
      }, 50);
    }, 600);
  };

  const blur = (force?: boolean) => {
    const element = document.querySelector('.vjs-fullscreen');
    if (element) return;
    if (force && canClose.value) {
      isVisible.value = false;
      canClose.value = false;
    }
    if (isVisible.value) return;
    clearTimeout(hoverInterval);
  };

  return {
    coordinates,
    isVisible,
    hover,
    blur,
  };
};
