import type { ComputedRef, MaybeRef } from 'vue';
import { type UseQueryOptions } from '@tanstack/vue-query';
import type { RouterMethod } from 'h3';

export type TMaybeRef<T> = {
  [K in keyof T]: MaybeRef<T[K]> | ComputedRef<T[K]>;
};
export type TPersonRole = 'actor' | 'director' | 'scriptwriter' | 'producer' | 'other';
export enum MoviesApi {
  banner = 'slider-banners/',
  films = 'films/',
  popular = 'popular/',
  collections = 'film-collections/',
}

type pagination<T> = T extends undefined ? never : T;
export interface IQueryParams<T = any> {
  key?: string | string[];
  params?: T;
  method?: RouterMethod;
  // pagination: pagination<boolean>;
  options?: Omit<UseQueryOptions, 'queryKey' | 'queryFn'>;
}

export type IPersonRole = 'actor' | 'director' | 'scriptwriter' | 'producer' | 'other';

export interface IMoviesParams {
  announcement?: boolean;
  first_symbol?: string;
  kp_to?: number;
  kp_from?: number;
  imdb_to?: number;
  imdb_from?: number;
  year_to?: number;
  year_from?: number;
  rating_from?: number;
  rating_to?: number;
  min_age?: number;
  name?: string;
  genres?: string;
  genres_slug?: string;
  years?: string;
  country_original?: string;
  novelty?: string | number;
  category?: string;
  obtain?: boolean;
  person?: number | string;
  legal?: string;
  unlisted?: string;
  is_free?: boolean | string;
  scheels?: string[] | string;
  studios?: string;
  ordering?: 'popularity' | '-filmclub_rating' | 'release';
  page?: number;
  page_size?: number;
  person_role?: TPersonRole;
  is_4k?: boolean;
  premiere?: boolean;
  select_flex?: boolean;
  new_seria?: boolean;
  new_season?: boolean;
  all_series?: boolean;
  top_flex?: boolean;
}

export interface PaymentProperties {
  id?: number;
  related_tariff?: number;
  scenario?: string;
  domain?: string;
  is_reccurent?: boolean;
  coupon?: string;
  reuse_card?: boolean;
  card?: number;
  payment_service?: string;
  platform?: 'android' | 'ios' | 'androidtv' | 'tvos' | 'tizen' | 'webos';
  currency?: string;
  utm_tags?: string;
  back_redirect?: string,
}

export interface IPaymentMovieParams {
  film?: number;
  domain?: string;
  reuse_card?: boolean;
  obtain_type?: 'purchase' | 'hd_lease' | 'sd_lease';
  card?: any;
  utms?: string;
  back_redirect?: string,
}

export enum ProfileApi {
  tariffs = 'v4/tarrifs/',
  payments = 'v4/user/payments/',
  subscription = 'v4/user/subscription/',
  notificatinons = 'v4/notifications/',
  notificatinonsFilters = 'v4/user/notification-settings-list/',
  notificationUpdate = 'v4/user/notification-settings-update/',
  readAllUserNotifications = 'v4/notifications/read-all-mobile-notifies/',
  userBonuses = 'v4/referral/bonuses/?is_used=true',
  userAccruals = 'v4/referral/accruals/',
  user = 'v4/user/',
  userCards = 'v4/user/cards/',
  userRecurrentCards = 'v4/user/recurrent-cards/',
  addNewUserCard = 'v4/user/new-card-add/',
  devices = 'v4/devices/',
  deleteAllDevice = 'v4/devices/delete-all/',
  deleteDevice = 'v4/devices/by-id/',
  getRejectReasons = 'v4/payment/rejection_reasons/',
  payment = 'v4/payment/',
  balanceAdd = 'v4/balance/add/',
  paymentInfo = 'v4/payment/info/',
  availableBonuses = 'v4/bonuses/available/',
  usedDiscount = 'v4/user/used-discount/',
  offers = 'v4/referral/offers/',
  sendCode = 'v5/web/user/send-code/',
}

export type TPlatform = 'persite' | 'land_auth' | 'landing' | 'android' | 'ios' | 'tvos' | 'androidtv' | 'tizen' | 'webos';

export interface ISiteElement {
  title?: string;
  text?: string;
  image: string;
  image_tablet?: string;
  image_mobile?: string;
  key: string;
  button_text: string;
  link?: string;
  advertiser: string;
  slug?: string;
}

export interface IGetSiteElementsParams {
  platform?: TPlatform;
  slug?: string;
  key?: string;
  page?: number;
}

export interface IGetSiteElementsResponse {
  count: number;
  next?: string;
  previous?: string;
  results: ISiteElement[];
}