<script lang="ts" setup generic="T">
  import RouletteSkeleton from '~/components/sliders/roulette/RouletteSkeleton.vue';
  import MovieCard from '~/components/cards/MovieCard.vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { FreeMode } from 'swiper/modules';
  import { isNullable } from '~/helpers/common';
  import type { Swiper as ISwiper, SwiperOptions } from 'swiper/types';
  import type { IMovieCard } from '~/types/movies';
  import type { ExtractPublicPropTypes } from 'vue';
  import type { TEmptyBlock } from '~/types/common';

  const VButton = defineAsyncComponent(() => import('~/components/ui/VButton.vue'));

  interface IProps {
    items: T[] | T;
    settings?: SwiperOptions;
    isFetching?: boolean;
    isLoaded?: boolean;
    isSuccess?: boolean;
    skeleton?: boolean;
    appendClass?: string;
    hideNavigation?: boolean;
    empty?: TEmptyBlock;
    normalHeight?: boolean;
    hideCardsStatus?: boolean;
    removeMovieHoverPopup?: boolean;
  }
  const props = defineProps<IProps>();
  const modules = [FreeMode];
  const options: SwiperOptions = {
    modules,
    speed: 700,
    slidesPerView: 'auto',
    freeMode: true,
    breakpoints: {
      640: {
        freeMode: false,
      },
    },
  };
  const settings: ExtractPublicPropTypes<typeof Swiper> = { ...options, ...props.settings };

  const swiperInstance = ref<ISwiper | null>(null);
  const onSwiper = (instance: ISwiper) => {
    swiperInstance.value = instance;
  };
  const slideNext = () => {
    swiperInstance.value!.slideNext(500);
  };
  const slidePrev = () => {
    swiperInstance.value!.slidePrev(500);
  };
  const isEnd = ref(true);
  const isBeginning = ref(false);
  const visibleSlides = (event: ISwiper) => {
    isEnd.value = !event.isEnd;
    isBeginning.value = !event.isBeginning;
  };

  const isClient = import.meta.client;
  // onMounted(() => {
  //   const referenceEl = document.querySelector('#referenceEl');
  //   const refFloating = document.querySelector('#refFloating');
  //   computePosition(referenceEl, refFloating, {
  //     middleware: [shift()],
  //   });
  // });
</script>

<template>
  <div
    v-if="(!isSuccess && !isClient) || (isFetching && isClient) || !isNullable(items) || !props.empty"
    class="roulette-slider"
    :class="appendClass"
  >
    <template v-if="!isNullable(items) || !props.skeleton">
      <transition name="fade">
        <div v-if="isBeginning && !props.hideNavigation" class="roulette-slider__control left">
          <v-button
            appearance="outline"
            icon="arrow-left-btn"
            color="white"
            title="Стрелка влево"
            rounded
            @click="slidePrev"
          />
        </div>
      </transition>
      <transition name="fade">
        <div v-if="isEnd && !props.hideNavigation" class="roulette-slider__control right">
          <v-button
            appearance="outline"
            icon="arrow-right-btn"
            color="white"
            title="Стрелка вправо"
            rounded
            @click="slideNext"
          />
        </div>
      </transition>
      <nuxt-error-boundary>
        <swiper v-bind="settings" @swiper="onSwiper" @progress="visibleSlides">
          <swiper-slide v-for="(slide, index) in props.items" :key="slide.id" v-slot="{ isActive }">
            <slot :movie="slide" :key="index" :active="isActive">
              <movie-card :movie="slide as IMovieCard" :hide-status="hideCardsStatus" :remove-hover-popup="removeMovieHoverPopup" />
            </slot>
          </swiper-slide>
        </swiper>
      </nuxt-error-boundary>
    </template>
    <template v-else>
      <slot name="skeleton">
        <roulette-skeleton class="roulette-slider__skeleton" />
      </slot>
    </template>
  </div>
  <v-empty-block v-else :template="props.empty" />
</template>

<style lang="scss">
  .roulette-slider {
    position: relative;
    .swiper {
      height: 100%;
      position: relative;
      display: grid;

      .swiper-slide {
        height: fit-content;
        display: flex;
        transform: none !important;
        width: auto;
        flex-shrink: 0;
        max-height: 100%;
        margin-right: 24px;
      }
      .swiper-slide__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
          justify-content: flex-start;
        }
        &.last {
          justify-content: flex-end;
        }
      }
    }
    .swiper-wrapper {
      margin: 64px 0 56px 0;
      height: fit-content;
    }
    &__control {
      position: absolute;
      z-index: 2;
      height: 90%;
      display: flex;
      align-items: center;
      button {
        backdrop-filter: blur(12px);
      }

      &.left {
        left: -84px;
      }
      &.right {
        width: 100px;
        justify-content: flex-end;
        right: 0;
      }
    }
    .control-normal & {
      &__control {
        height: 100%;
      }
    }
    @media (max-width: $laptop-small) {
      &__control {
        display: none;
      }
    }
    @media (max-width: $retina) {
      .swiper {
        .swiper-slide {
          margin-right: 16px;
        }
        .swiper-wrapper {
          margin: 24px 0;
        }
      }
    }
  }
</style>
