import { default as indexZvkm969IINMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexk9U4LsuVLYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersRtEvK2IU0iMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriesZESFxSpNDRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListaseqTLzPD0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersJrIaJ69uTlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenresNJ2EhKh9QdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMovies35wSfr6rPcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularL7E6NeASLTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSerieszP7WlCdZXlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersd2iluXgMZKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenreslSFMQf0A2UMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93KZB45aTYhTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/genre/[slug].vue?macro=true";
import { default as index6cYkezdXmgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93SOw7NGaGr7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/collections/[slug].vue?macro=true";
import { default as indexmEDPVDoR0QMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/collections/index.vue?macro=true";
import { default as copyrightLzQPfZUelrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/copyright.vue?macro=true";
import { default as _91id_93jhSdU2vqOVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/crew/[id].vue?macro=true";
import { default as indexoHErPNIpP5Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/faq/index.vue?macro=true";
import { default as alltl6cFmzJ03Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtg08pfFB8SjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueXBmcsvvrBtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavorites9iwZyEyNzhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedUuJHdqXl8cMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueMcyoLZyxCJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/continue.vue?macro=true";
import { default as indexf43AY8D26vMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/index.vue?macro=true";
import { default as purchasedvssONIISeuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedD5n1EPeqF7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policyIJY6WUhCncMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagespeKpvZGGFJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBanneri3Kxza8BwfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsAe3RkZuCg9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinue1NEk0H9g6JMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsmilBX3YzYuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresZ40ZTwcRBmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderPpDtv102P3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopular3K0APxFJToMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersJNqd4zp4AlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeries77DCQyrhSQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsjanNt6zBKBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizegidqaxUw6RMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcomingztcohpFbw0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexbCbTelyssSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93FqQG8J64oyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstallvBIHcqpoyrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallwuydBstpdyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesqVV7XY1kO8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/types.ts?macro=true";
import { default as useInstall6pSwKrztClMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrew1mtSxNY9tkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexZt0tjDPc5gMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollections1xrNL8d0QAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewU56hQVzfNXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionfhd3nzzzubMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeadereueZCw7myXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticexXpzWaMwEfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeleton1RvHmNHx8BMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButtonSr05UF2Q4aMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedOSYKUDCcyxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeries8zeAUhbSDwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailers0zvEiEkywDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsbwKAAo2jjUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93MkXwJCadWBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/news/[slug].vue?macro=true";
import { default as indexOYHZirQzryMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsxYsdFv5M0cMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45seriesf4nVdD2bY8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementm0m7Hl8rIwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightn9olEvXdqOMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holderNxo9O7paw9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privatexWvGQciIWDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-private.vue?macro=true";
import { default as policy_45userrTfL3ECAzqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-user.vue?macro=true";
import { default as premiersB54lZeqeapMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/premiers.vue?macro=true";
import { default as private_45policyV4SNNcLinuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/private-policy.vue?macro=true";
import { default as indexlHRMtHIsAPMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalM2yQQKtRlQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileYm9KvcQqw1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModalABtxfeLH7LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDataLI0VpjeeKmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModaldlP9F7TbP8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerZFGdS1zq7wMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationbTetGgPNDFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistorycrZEtcf6GqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardq3W7RwZIaGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsqonQ4JvmeyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPayments3N4int0cwmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsTHG5MaiAlaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgram7PPbDVhQJ0Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionsq1n3e1vNIhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexNl0EsYPdoiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/devices/index.vue?macro=true";
import { default as indexfyXFAVeomIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/index.vue?macro=true";
import { default as indexPnE68McR18Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexCkhksE6yP6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/payments/index.vue?macro=true";
import { default as indexvK7TYFpWLqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/settings/index.vue?macro=true";
import { default as indexW8QbPzLD8DMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/user/index.vue?macro=true";
import { default as resultAXiciUmZF3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/result.vue?macro=true";
import { default as _91slug_93KpPpyamzLbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/similar/[slug].vue?macro=true";
import { default as indexTnNyJOL5EXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/sports/channels/index.vue?macro=true";
import { default as index1SYHs2luDXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsl3kOU1na39Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContent6yYa16WZ5mMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicesBq8Duid7IWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadYbLdzxAMN3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderDV9gWNTe2JMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionszmwFIH1GlZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverDPZDeDOSXaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexyX9X6lnRJWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/index.vue?macro=true";
import { default as termstde7GOWim4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/terms.vue?macro=true";
import { default as upcoming5iN2jwKHYvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexlHRMtHIsAPMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexNl0EsYPdoiMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexfyXFAVeomIMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexPnE68McR18Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexCkhksE6yP6Meta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: indexvK7TYFpWLqMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: indexW8QbPzLD8DMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: index1SYHs2luDXMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-352954/src/pages/upcoming.vue").then(m => m.default || m)
  }
]